import React from 'react';
import { TickerTape } from "react-ts-tradingview-widgets";
const CoinMarketCapWidget = () => {
  return (
  <TickerTape displayMode="compact" colorTheme="dark" symbols={[{
    "description": "",
    "proName": "BINANCE:POLUSDT"
  },
  {
    "description": "",
    "proName": "BINANCE:ETHUSDT"
  },
  {
    "description": "",
    "proName": "BINANCE:BTCUSDT"
  },
  {
    "description": "",
    "proName": "BINANCE:LTCUSDT"
  },
  {
    "description": "",
    "proName": "KUCOIN:XMRUSDT"
  }]}></TickerTape>
  );
};

export default CoinMarketCapWidget;
